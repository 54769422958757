export const testCasinoInfo = {
  casinoId: 5,
  casinoName: "Vulpin Casino",
  logoUrl: "https://fiat1.securebet.xyz/bot/logo/5-logo_vulpin.png",
  logoColor: "rgb(255, 193, 32)",
  buttonColor: "rgb(255, 193, 32)",
  headerColor: "#FFF00F",
  groupId: 21,
  telegramWidget: true,
  langList: "ru,en,uz",
  langDefault: "ru",
  supportChat: "VulpinBotSupport2",
  channelUrl: "https://t.me/VulpinCasinoChannel",
  reviewUrl: "https://t.me/+wwQiO2u2rXc1NTI6",
  bonusImages: {
    desktop: {
      main: "/test/ban.png",
      // main: "/test/ban1.jpeg",
      // main: "../../images/test/ban.png",
      menu: "https://f.securebet.xyz/bot/bonus_images/desktop/4-menu-21515.png"
    },
    mobile: {
      main: "https://f.securebet.xyz/bot/bonus_images/mobile/4-main-21515_(5).png",
      menu: "https://f.securebet.xyz/bot/bonus_images/mobile/4-menu-2024-12-07_08.56.35.jpg"
    }
  },
  langLink: {
    ru: {
      supportChat: "VulpinBotSupport2",
      channelUrl: "https://t.me/VulpinCasinoChannel",
      reviewUrl: "https://t.me/+wwQiO2u2rXc1NTI6",
      chatUrl: "https://t.me/+j9GgEuoWuqhhYWM8",
      tgPostUrl: "https://t.me/VulpinCasinoChannel/350"
    },
    uz: {
      supportChat: "",
      channelUrl: "",
      reviewUrl: "",
      chatUrl: "https://t.me/+Q2U531fVFFBjMGIy",
      tgPostUrl: ""
    }
  },
  botName: "VulpinCasinoBot",
  htmlHead: "",
  htmlEndBody: "",
  excludeFromDemo: ["PGSoft", "Qilin"],
  gameCategories: [
    {
      id: 100,
      name: "3Оакс",
      providerId: 16,
      providerName: "3Oaks",
      games: [
        {
          game_id: 816,
          name: "15 Dragon Pearls",
          uuid: "6a6c1eb6155443449208f7fcfb76bc7c",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/6a6c1eb6155443449208f7fcfb76bc7c.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 815,
          name: "Wukong",
          uuid: "7d06f98541734ca9b0065adbfed864e8",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/7d06f98541734ca9b0065adbfed864e8.png",
          type: "slots",
          provider: "3Oaks",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 101,
      name: "Аматик",
      providerId: 13,
      providerName: "Amatic",
      games: [
        {
          game_id: 577,
          name: "Admiral Nelson",
          uuid: "71f8a9c71b524e34bab494ab71d89b34",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/71f8a9c71b524e34bab494ab71d89b34.jpg",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 602,
          name: "Royal Unicorn",
          uuid: "e9368eb68f8b4fcd855e68b7887ae5e9",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/e9368eb68f8b4fcd855e68b7887ae5e9.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 601,
          name: "Wild Dragon",
          uuid: "071cfc095cb447309b08792aeef3950a",
          source: "win2power:0",
          image:
            "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/Amatic/071cfc095cb447309b08792aeef3950a.png",
          type: "slots",
          provider: "Amatic",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 102,
      name: "Аппарат",
      providerId: 59,
      providerName: "Apparat",
      games: [
        {
          game_id: 2038,
          name: "1-2-3 Wilds on Fire",
          uuid: "e66147aed56740438dc30cb2b962aa59",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/e66147aed56740438dc30cb2b962aa59.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2019,
          name: "The Griffin",
          uuid: "92aa3c163b53429fb5fc25e8cdf98d49",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/92aa3c163b53429fb5fc25e8cdf98d49.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2017,
          name: "The Guardian God Heimdall's Horn",
          uuid: "16cdc9fcf5aa409d9232ac488546dac8",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/16cdc9fcf5aa409d9232ac488546dac8.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2011,
          name: "The Warlock's Book",
          uuid: "5b4ad38ee5e844cc88a1d34c5ff947bb",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/5b4ad38ee5e844cc88a1d34c5ff947bb.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2047,
          name: "The Warlock's Book - Buy Feature",
          uuid: "ec269bc9a36e4af08c267e9e9cf07a1e",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/ec269bc9a36e4af08c267e9e9cf07a1e.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2010,
          name: "Total Eclipse",
          uuid: "80c8217fb4524766b09ddcfec022651d",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/80c8217fb4524766b09ddcfec022651d.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2040,
          name: "Total Eclipse - Buy Feature",
          uuid: "fdc8c8d423484e21a3be174648a48d4a",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/fdc8c8d423484e21a3be174648a48d4a.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2031,
          name: "Total Eclipse XXL",
          uuid: "d6667ea087cb4c23b3ae6a7a4d14547f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/Apparat/d6667ea087cb4c23b3ae6a7a4d14547f.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2025,
          name: "Valkyries",
          uuid: "2a6ea3df3413a744b84ad2f538d8c8b42afbb708",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Apparat/2a6ea3df3413a744b84ad2f538d8c8b42afbb708.png",
          type: "slots",
          provider: "Apparat",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        }
      ]
    },
    {
      id: 103,
      name: "Аватар",
      providerId: 64,
      providerName: "AvatarUX",
      games: [
        {
          game_id: 2077,
          name: "80sPOP",
          uuid: "8eb58826e3794642b14dd3473a58ecab",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/8eb58826e3794642b14dd3473a58ecab.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2054,
          name: "Witchy POPpins",
          uuid: "c0654d53da2d4774b1abcdc14a2d6569",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/c0654d53da2d4774b1abcdc14a2d6569.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2076,
          name: "Worms Of Valor",
          uuid: "319d998059b849958fa204b191448003",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/319d998059b849958fa204b191448003.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 2064,
          name: "Zombie APOPlypse",
          uuid: "59108cdbc3bc4b129062dde760f31602",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/AvatarUX/59108cdbc3bc4b129062dde760f31602.png",
          type: "slots",
          provider: "AvatarUX",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    },
    {
      id: 104,
      name: "Авиатрикс",
      providerId: 60,
      providerName: "Aviatrix",
      games: [
        {
          game_id: 942,
          name: "Aviatrix",
          uuid: "9d9b5b34389337d4e43568b4ba2d56be97de447a",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/Aviatrix/9d9b5b34389337d4e43568b4ba2d56be97de447a.png",
          type: "crash",
          provider: "Aviatrix",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 1
        }
      ]
    },
    {
      id: 105,
      name: "БФГеймс",
      providerId: 47,
      providerName: "BFGames",
      games: [
        {
          game_id: 2207,
          name: "Alice In Wonderland",
          uuid: "9859b5135dc04af19fb718c721151a0f",
          source: "win2power:1",
          image: "https://static.turnkey.casino/games/BFGames/9859b5135dc04af19fb718c721151a0f.png",
          type: "slots",
          provider: "BFGames",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        },
        {
          game_id: 1893,
          name: "Wilds and Pirates Mobile",
          uuid: "76c78b4996de437d9d0a371477ead069",
          source: "win2power:1",
          image:
            "https://static.turnkey.casino/games/ZeusPlay/76c78b4996de437d9d0a371477ead069.png",
          type: "slots",
          provider: "ZeusPlay",
          priority: 1,
          favorites: false,
          enabled: 1,
          hit: 0
        }
      ]
    }
  ],
  bonusGames: [
    {
      id: 3,
      name: "Lucky Lady’s Charm",
      url: "/js-games/lucky_lady/game.html"
    },
    {
      id: 5,
      name: "Fruit Cocktail",
      url: "/js-games/fruit_cocktail/game.html"
    },
    {
      id: 2,
      name: "Book of Ra Classic",
      url: "/js-games/book_of_ra_classic/game.html"
    },
    {
      id: 40,
      name: "Lord of Ocean",
      url: "/js-games/lord_of_ocean/game.html"
    },
    {
      id: 23,
      name: "Sharky",
      url: "/js-games/sharky/game.html"
    },
    {
      id: 19,
      name: "Beetle Mania Deluxe",
      url: "/js-games/beetle_mania/game.html"
    },
    {
      id: 36,
      name: "Book of Ra Deluxe",
      url: "/js-games/book-of-ra-deluxe-2/game.html"
    },
    {
      id: 20,
      name: "Sizzling Hot Deluxe",
      url: "/js-games/sizzling_hot_deluxe/game.html"
    },
    {
      id: 48,
      name: "Diamond 7",
      url: "/js-games/diamond7/game.html"
    },
    {
      id: 18,
      name: "Just Jewels Deluxe",
      url: "/js-games/just_jewels_deluxe/game.html"
    },
    {
      id: 44,
      name: "Lucky Lady’s Charm",
      url: "/js-games/lucky_lady_deluxe/game.html"
    },
    {
      id: 45,
      name: "Queens of Hearts",
      url: "/js-games/queen_of_hearts/game.html"
    }
  ],
  gameHits: [
    {
      game_id: 817,
      name: "3 Coins",
      uuid: "a7be71ce60f34c78b72bb728c6cf2e0c",
      source: "win2power:0",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/a7be71ce60f34c78b72bb728c6cf2e0c.png",
      type: "slots",
      provider: "3Oaks",
      excludeDemoMode: false
    },
    {
      game_id: 802,
      name: "Sticky Piggy",
      uuid: "1e5e042292d240fd827cee79720ddc65",
      source: "win2power:0",
      image:
        "https://r.gamesstatic.com/games/0c8c4534ac47f1e4d055425f16076983/3Oaks/1e5e042292d240fd827cee79720ddc65.png",
      type: "slots",
      provider: "3Oaks",
      excludeDemoMode: false
    },
    {
      game_id: 2025,
      name: "Valkyries",
      uuid: "2a6ea3df3413a744b84ad2f538d8c8b42afbb708",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/Apparat/2a6ea3df3413a744b84ad2f538d8c8b42afbb708.png",
      type: "slots",
      provider: "Apparat",
      excludeDemoMode: false
    },
    {
      game_id: 942,
      name: "Aviatrix",
      uuid: "9d9b5b34389337d4e43568b4ba2d56be97de447a",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/Aviatrix/9d9b5b34389337d4e43568b4ba2d56be97de447a.png",
      type: "crash",
      provider: "Aviatrix",
      excludeDemoMode: false
    },
    {
      game_id: 2222,
      name: "Book of Gods",
      uuid: "515cbd3d87a3402b8a08f5a452fd64cd",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/BFGames/515cbd3d87a3402b8a08f5a452fd64cd.png",
      type: "slots",
      provider: "BFGames",
      excludeDemoMode: false
    },
    {
      game_id: 2123,
      name: "7 Fruits",
      uuid: "16e052082e546654d9c21cf6c00c9644da9b650e",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/16e052082e546654d9c21cf6c00c9644da9b650e.png",
      type: "slots",
      provider: "Belatra Games",
      excludeDemoMode: false
    },
    {
      game_id: 2506,
      name: "Beer Bonanza",
      uuid: "ac437469dda9d4e0a82059d405540eec85d111d2",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/ac437469dda9d4e0a82059d405540eec85d111d2.png",
      type: "slots",
      provider: "BGaming",
      excludeDemoMode: false
    },
    {
      game_id: 3059,
      name: "777 Deluxe",
      uuid: "515fc4e58e8e4084b46f23d50f355ecb",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/3962be5e18b1e84fdd95613e87dfda1a/Blueprint/515fc4e58e8e4084b46f23d50f355ecb.png",
      type: "slots",
      provider: "Blueprint",
      excludeDemoMode: false
    },
    {
      game_id: 4134,
      name: "Fire dragon",
      uuid: "1560f2cf9eb84418a45ca610b646fc24",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/FBastards/1560f2cf9eb84418a45ca610b646fc24.png",
      type: "slots",
      provider: "FBastards",
      excludeDemoMode: false
    },
    {
      game_id: 4140,
      name: "Mafia",
      uuid: "925d3b2e85f945d98800e3dacbf0c41b",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/FBastards/925d3b2e85f945d98800e3dacbf0c41b.png",
      type: "slots",
      provider: "FBastards",
      excludeDemoMode: false
    },
    {
      game_id: 127,
      name: "Sizzling 6",
      uuid: "e355fffe8405129a5ef8b6a5e77ff83d0a118de4",
      source: "win2power:0",
      image: "https://r.gamesstatic.com/games/e355fffe8405129a5ef8b6a5e77ff83d0a118de4.jpg",
      type: "slots",
      provider: "Greentube",
      excludeDemoMode: false
    },
    {
      game_id: 124,
      name: "Sizzling Hot Deluxe",
      uuid: "557af416d9831dccc0bd5fdfe47eaa41c60015ad",
      source: "win2power:0",
      image: "https://r.gamesstatic.com/games/557af416d9831dccc0bd5fdfe47eaa41c60015ad.jpg",
      type: "slots",
      provider: "Greentube",
      excludeDemoMode: false
    },
    {
      game_id: 1606,
      name: "Big Bass - Hold & Spinner",
      uuid: "46fd364683164ddbb528466ff23987e1",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/46fd364683164ddbb528466ff23987e1.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1530,
      name: "Big Bass - Keeping it Reel",
      uuid: "d1acf2e3d4bce1feaa8e86c73521b5337322b509",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/d1acf2e3d4bce1feaa8e86c73521b5337322b509.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1288,
      name: "Big Bass Bonanza",
      uuid: "76b3846996214fa09c1f761a0228c032",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/76b3846996214fa09c1f761a0228c032.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1884,
      name: "Big Bass Double Down Deluxe",
      uuid: "bf03ab99344f46b8ab2af03005e722bf",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/bf03ab99344f46b8ab2af03005e722bf.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1382,
      name: "Big Juan",
      uuid: "922337fede347c8bb8e0e273a4f06dc99f04bd9c",
      source: "win2power:1",
      image: "https://static.turnkey.casino/games/922337fede347c8bb8e0e273a4f06dc99f04bd9c.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    },
    {
      game_id: 1058,
      name: "The Dog House Megaways",
      uuid: "b3a22c28284e4de69efb358940838cb1",
      source: "win2power:1",
      image:
        "https://static.turnkey.casino/games/PragmaticPlay/b3a22c28284e4de69efb358940838cb1.png",
      type: "slots",
      provider: "PragmaticPlay",
      excludeDemoMode: false
    }
  ],
  banners: [
    {
      id: 74,
      name: "Слотохаб",
      position: 1,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText: "<p><br></p>",
      relativeUrl: "",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/slotohub.jpg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/slotohub копія.png"
    },
    {
      id: 3,
      name: "20% cashback",
      position: 2,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "<p><b>КЕШБЭК 20% КАЖДЫЙ ДЕНЬ!</b></p><p><br></p><p><u>Дарим кешбэк 20% на каждый депозит и каждый день без вейджера!</u></p><p><br></p><p><b>Шаги:</b></p><p>1) Сделай депозит на любую сумму;</p><p>2) Получи <b>20%</b> от суммы всех твои депозитов за календарный день.</p><p><br></p><p><b>Условия акции:</b></p><p>1) Для участия необходимо сделать 1 депозит или несколько депозитов в течение одного календарного дня;</p><p>2) По сумме всех депозитов за календарный день будет начислен кешбэк 20% от суммы всех депозитов;</p><p>3) Вейджер кешбэка - 0;</p><p>4) Кешбэк начисляется на следующий календарь день до 13:00 (UTC +3);</p><p>5) После начисления кешбэк сразу доступен к выводу;</p><p>6) Максимальная сумма кешбэка не ограничена;</p><p>7) При подозрении на мошеннические действия и при попытках обмана, Slotohub имеет право не начислять бонус.</p>",
      relativeUrl: "daily_cashback",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/photo_2024-06-29-16.20.17.png",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/photo_2024-06-29-16.19.51 (1).png"
    },
    {
      id: 45,
      name: "100% бонус на первый депозит",
      position: 2,
      language: "ru",
      gameType: 0,
      gameId: 0,
      bannerText:
        "100% бонус на первый депозит до 250000 рублей!Шаги:1) Сделай депозит от 300 рублей;2) Получи 100% от суммы твоего первого депозита до 250000 рублей.Условия акции:1) Для участия в акции необходимо сделать депозит от 300 рублей;2) Минимальная сумма бонуса - 300 рублей, максимальная - 250000 рублей;3) Бонус начисляется 100% от суммы первого депозита (например, при пополнении на 1000 рублей, будет начислен бонус в размере 1000 рублей);4) Бонус начисляется автоматически после совершения успешного депозита;5) Вейджер бонуса - 30х, кешаут бонуса - 3х;6) При подозрении на мошеннические действия и при попытках обмана, Slotohub имеет право не начислять бонус или аннулировать бонус.",
      relativeUrl: "welcome",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/photo_2024-07-13 16.43.43.jpeg",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/photo_2024-07-13 16.43.35 (1).jpeg"
    },
    {
      id: 6,
      name: "",
      position: 3,
      language: "ru",
      gameType: 1,
      gameId: 54,
      bannerText: "",
      relativeUrl: "",
      externalUrl: "",
      fileUrl: "https://fiat1.securebet.xyz/images/banners/IMG_9213.JPEG",
      mobileFileUrl: "https://fiat1.securebet.xyz/images/banners/IMG_9214.jpg"
    }
  ],
  winnings: {
    changed: "2024-11-13 11:18:09",
    list: [
      {
        gameId: 5772,
        win: 2968
      },
      {
        gameId: 8154,
        win: 2784
      },
      {
        gameId: 3260,
        win: 3525
      },
      {
        gameId: 2776,
        win: 4543
      },
      {
        gameId: 2591,
        win: 1253
      },
      {
        gameId: 4258,
        win: 183
      },
      {
        gameId: 3277,
        win: 2404
      },
      {
        gameId: 2281,
        win: 4531
      },
      {
        gameId: 2586,
        win: 1399
      },
      {
        gameId: 6269,
        win: 4860
      },
      {
        gameId: 994,
        win: 1280
      },
      {
        gameId: 3794,
        win: 796
      },
      {
        gameId: 2255,
        win: 427
      },
      {
        gameId: 1699,
        win: 2110
      },
      {
        gameId: 6238,
        win: 1330
      },
      {
        gameId: 5476,
        win: 3836
      },
      {
        gameId: 805,
        win: 2022
      },
      {
        gameId: 8137,
        win: 4905
      },
      {
        gameId: 3887,
        win: 1420
      },
      {
        gameId: 7475,
        win: 673
      },
      {
        gameId: 142,
        win: 4785
      },
      {
        gameId: 3358,
        win: 2054
      },
      {
        gameId: 6837,
        win: 3163
      },
      {
        gameId: 6911,
        win: 1827
      },
      {
        gameId: 5611,
        win: 3872
      }
    ]
  }
};

export const testBalance = {
  RUB: {
    info: {
      decimal: 0,
      type: "fiat",
      current: false
    },
    balances: {
      main: 0,
      payout: 0,
      bonus: 500.1111111111111,
      depositWager: 0,
      bonusWager: 500.9999999999999
    }
  },
  EUR: {
    info: {
      decimal: 2,
      type: "fiat",
      current: false
    },
    balances: {
      main: 0,
      payout: 0,
      bonus: 0,
      depositWager: 0,
      bonusWager: 0
    }
  },
  UZS: {
    info: {
      decimal: 0,
      type: "fiat",
      current: false
    },
    balances: {
      main: 0,
      payout: 0,
      bonus: 0,
      depositWager: 0,
      bonusWager: 0
    }
  },
  USDT: {
    info: {
      decimal: 2,
      type: "crypto",
      current: true
    },
    balances: {
      main: 0,
      payout: 0,
      bonus: 0,
      depositWager: 0,
      bonusWager: 0
    }
  }
};

export const testPaymentInfo = {
  currency: "RUB",
  userData: "card",
  bankList: {
    phone: ["sber", "alpha", "tbank", "vtb", "sovcom", "rossel", "yasbp"]
  },
  minAmount: 5000,
  maxAmount: 100000,
  taxPercent: 5,
  taxFixed: 0,
  extraTaxAmount: 10000,
  extraTaxPercent: 10
};

export const testDeposit = {
  currency: "USDT",
  minAmount: 2,
  maxAmount: 50002
};

export const testBonus = {
  currency: "RUB",
  registration: {
    toDeposit: true,
    values: {
      amount: 500,
      wager: 2,
      cashout: 3
    }
  },
  firstDeposit: {
    toDeposit: true,
    values: {
      amount: 5001,
      wager: 2,
      cashout: 3,
      minDeposit: 301
    }
  },
  cashback: {
    toDeposit: false,
    values: {
      amount: 5000,
      wager: 10,
      cashout: 3,
      percent: 15,
      minDeposit: 300
    }
  }
};

export const testJackpots = {
  list: {
    currency: "EUR",
    grand: {
      amount: 200,
      minBet: 0,
      minDeposit: 0,
      depositDays: 0,
      drop: true
    },
    major: {
      amount: 0,
      minBet: 0,
      minDeposit: 0,
      depositDays: 0,
      drop: false
    },
    mini: {
      amount: 0,
      minBet: 0,
      minDeposit: 0,
      depositDays: 0,
      drop: false
    }
  }
};
